<template>
  <div class="my-3">
    <button
      class="button is-small is-primary"
      title="Edit Bank Account"
      @click="openModal"
    >
      Edit Bank Account
    </button>

    <div
      class="modal"
      :class="{'is-active': isModalOpen}"
    >
      <div
        class="modal-background"
        @click="closeModal"
      />

      <div class="modal-content">
        <div class="box">
          <div class="is-flex is-flex-wrap-wrap columns">
            <div class="field is-full column">
              <label class="label">Bank</label>
              <div class="select is-fullwidth is-size-6">
                <select v-model="bankAccountToEdit.bankId">
                  <option
                    v-for="bank in banks"
                    :key="bank.bankId"
                    :value="bank.bankId"
                  >
                    {{ bank.name }} / {{ bank.code }}
                  </option>
                </select>
              </div>
            </div>

            <div class="field is-half column">
              <label class="label">Account Number</label>
              <div class="control">
                <input
                  v-model="bankAccountToEdit.accountNumber"
                  class="input"
                  type="text"
                  placeholder="Enter account number"
                >
              </div>
            </div>

            <div class="field is-half column">
              <label class="label">Company Name</label>
              <div class="control">
                <input
                  v-model="bankAccountToEdit.companyName"
                  class="input"
                  type="text"
                  placeholder="Enter company name"
                >
              </div>
            </div>

            <div class="field is-half column">
              <label class="label">Branch Name</label>
              <div class="control">
                <input
                  v-model="bankAccountToEdit.branchName"
                  class="input"
                  type="text"
                  placeholder="Enter branch name"
                >
              </div>
            </div>

            <div class="field is-half column">
              <label class="label">Branch Code</label>
              <div class="control">
                <input
                  v-model="bankAccountToEdit.branchCode"
                  class="input"
                  type="text"
                  placeholder="Enter branch code"
                >

                <p
                  v-if="errors.branchCode"
                  class="help is-danger"
                >
                  {{ errors.branchCode }}
                </p>
              </div>
            </div>

            <div class="field is-half column">
              <label class="label">Phrase</label>
              <div class="control">
                <input
                  v-model="bankAccountToEdit.phrase"
                  class="input"
                  type="text"
                  placeholder="Enter phrase"
                >
              </div>
            </div>

            <div class="field is-half column">
              <label class="label">URL</label>
              <div class="control">
                <input
                  v-model="bankAccountToEdit.website"
                  class="input"
                  type="text"
                  placeholder="Enter URL"
                >
              </div>
            </div>

            <div class="field is-grouped is-grouped-right column">
              <p class="control">
                <button
                  class="button is-primary"
                  :class="{ 'is-loading': isLoading }"
                  :disabled="isSaveButtonActive()"
                  @click="bankAccountEdition"
                >
                  Save
                </button>
              </p>
              <p class="control">
                <a
                  class="button is-light"
                  @click="closeModal"
                >
                  Cancel
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue';

export default {
    props: {
        bankAccount: {
            type: Object,
            default: null,
        },
        banks: {
            type: Object,
            default: null,
        },
    },

    emits: ['bankAccountEdited'],

    async setup(props, { emit }) {
        const {
            editBankAccount,
        } = inject('bank_accounts');

        const bankAccountToEdit = ref({
            bankAccountId: props.bankAccount.bankAccountId,
            accountNumber: props.bankAccount.accountNumber,
            companyName: props.bankAccount.companyName,
            branchName: props.bankAccount.branchName,
            branchCode: props.bankAccount.branchCode,
            phrase: props.bankAccount.phrase,
            website: props.bankAccount.website,
            bankId: props.bankAccount.bank.bank_id,
        });

        const isLoading = ref(false);
        const isModalOpen = ref(false);
        const errors = ref({});

        const isAvailabilityModalOpen = ref(false);
        const errorsAvailability = ref({});

        const closeModal = () => {
            isModalOpen.value = false;
        };

        const openModal = () => {
            isModalOpen.value = true;
            errors.value = {};
        };

        const openModalAvailability = () => {
            isAvailabilityModalOpen.value = true;
            errorsAvailability.value = {};
        };

        const isSaveButtonActive = () => {
            const requiredFields = [
                'bankId',
                'accountNumber',
                'companyName',
                'branchName',
                'branchCode',
            ];
            return !requiredFields.every((keyName) => (
                bankAccountToEdit.value[keyName]
                && bankAccountToEdit.value[keyName].trim()
            ));
        };

        const validate = () => {
            if (bankAccountToEdit.value.branchCode.length !== 3) {
                errors.value.branchCode = 'Branch Code should three characters.';
                return false;
            }

            return true;
        };

        const bankAccountEdition = async () => {
            if (validate()) {
                const editedAccountBank = await editBankAccount(bankAccountToEdit.value);
                emit('bankAccountEdited', editedAccountBank);
                closeModal();
            }
        };

        return {
            bankAccountEdition,
            bankAccountToEdit,
            closeModal,
            errors,
            isLoading,
            isModalOpen,
            isSaveButtonActive,
            openModal,
            openModalAvailability,
        };
    },
};
</script>
