/* eslint-disable camelcase */
import { ref, watch, toRaw } from 'vue';
import {
    httpGet, httpPost, httpPatch, getCsv,
} from '../http';
import initializeFlagsmith from '../flagsmith';
import { getCurrentUsername } from '../authentication';

const PATH = 'btb-transactions';

export default (filters, ordering) => {
    const transactions = ref([]);
    const maxPage = ref(0);
    const currentPage = ref(1);
    const isLoading = ref(false);

    const snakeToCamel = (res) => res.map(({
        merchant_id: merchantId,
        first_name: firstName,
        surname,
        nonce,
        transaction_id: transactionId,
        date,
        currency,
        settled_amount: settledAmount,
        authorized_amount: authorizedAmount,
        email,
        status,
        received_by: receivedBy,
        processing_fee: processingFee,
        bank_name: bankName,
        bank_code: bankCode,
        branch_code: branchCode,
        account_number: accountNumber,
        company_name: companyName,
        source_bank_name: sourceBankName,
        transaction_request_date,
        creation,
        page,
        transaction_fee: transactionFee,
        user_identification: userIdentification,
        sender_name: senderName,
    }) => ({
        merchantId,
        firstName,
        surname,
        nonce,
        transactionId,
        timestamp: new Date(date),
        currency,
        settledAmount,
        authorizedAmount,
        email,
        status,
        receivedBy,
        processingFee,
        bankName,
        bankCode,
        branchCode,
        accountNumber,
        companyName,
        sourceBankName,
        transactionRequestDate: new Date(transaction_request_date),
        creation,
        page,
        transactionFee,
        ipAddress: userIdentification?.ip_address || '',
        userAgent: userIdentification?.user_agent || '',
        fingerprint: userIdentification?.fingerprint || '',
        senderName,
    }));

    const camelToSnake = (({
        amountFrom: amount_from,
        amountTo: amount_to,
        authorizedAmountFrom: authorized_amount_from,
        authorizedAmountTo: authorized_amount_to,
        currency,
        email,
        firstName: first_name,
        surname,
        nonce,
        merchantId: merchant_id,
        status,
        receivedBy: received_by,
        dateFrom: date_from,
        dateTo: date_to,
        transactionId: transaction_id,
        bankName: bank_name,
        bankCode: bank_code,
        branchCode: branch_code,
        accountNumber: account_number,
        companyName: company_name,
        sourceBankName: source_bank_name,
        creation,
        receiptDateFrom: receipt_date_from,
        receiptDateTo: receipt_date_to,
        ipAddress: ip_address,
        userAgent: user_agent,
        senderName: sender_name,
        fingerprint,
    }) => ({
        amount_from: (
            amount_from.trim() !== '' ? Math.round(amount_from * 100) : ''
        ),
        amount_to: (
            amount_to.trim() !== '' ? Math.round(amount_to * 100) : ''
        ),
        authorized_amount_from: (
            authorized_amount_from.trim() !== '' ? Math.round(authorized_amount_from * 100) : ''
        ),
        authorized_amount_to: (
            authorized_amount_to.trim() !== '' ? Math.round(authorized_amount_to * 100) : ''
        ),
        currency,
        email,
        first_name,
        surname,
        nonce,
        merchant_id: merchant_id !== '' ? toRaw(merchant_id).map((merchant) => merchant.id) : '',
        status,
        received_by,
        date_from: date_from !== null ? date_from.toISODate() : null,
        date_to: date_to !== null ? date_to.toISODate() : null,
        transaction_id,
        bank_name: bank_name !== '' ? toRaw(bank_name).map((bank) => bank.name) : '',
        bank_code,
        branch_code,
        account_number: account_number !== '' ? toRaw(account_number).map((acc) => acc.name) : '',
        company_name,
        source_bank_name,
        creation,
        receipt_date_from: receipt_date_from !== null ? receipt_date_from.toISODate() : null,
        receipt_date_to: receipt_date_to !== null ? receipt_date_to.toISODate() : null,
        ip_address,
        user_agent,
        sender_name,
        fingerprint,
    }));

    const getAuthorizedAmount = async (set_amount, id) => {
        const amount = await httpGet('authorized-amount/', { settled_amount: `${set_amount}`, merchant_id: `${id}` });
        return amount;
    };

    const fetchTransactions = async (page = 1) => {
        isLoading.value = true;
        try {
            const flagsmith = await initializeFlagsmith();

            const response = await httpGet(
                PATH,
                {
                    ...camelToSnake(filters),
                    ordering: ordering.value,
                    page,
                },
            );

            transactions.value = snakeToCamel(flagsmith.hasFeature('btb_pagination') ? response.transactions : response);
            maxPage.value = response.pages;
        } finally {
            isLoading.value = false;
        }
    };

    const fetchTransactionsCsv = async () => {
        const response = await getCsv(
            PATH,
            {
                ...camelToSnake(filters),
                ordering: ordering.value,
            },
        );
        return response;
    };

    const postReceive = async (id) => {
        const response = await httpPost(`btb-transactions/receive/${id}/`);
        return response;
    };

    const patchTransaction = async (id, body) => {
        const response = await httpPatch(
            `btb-transactions/${id}/`,
            {},
            body,
        );
        return response;
    };

    const addTransaction = async (body) => {
        const response = await httpPost('btb-transactions/create/manual/', {}, body);
        const responseInCamel = snakeToCamel([response])[0];
        transactions.value = [...transactions.value, responseInCamel];
        return response;
    };

    const addRefund = async (body) => {
        const response = await httpPost('btb-transactions/create/refund/', {}, body);
        const responseInCamel = snakeToCamel([response])[0];
        transactions.value = [...transactions.value, responseInCamel];
        return response;
    };

    const updateTransactionValues = (id, body) => {
        const transactionIndex = transactions.value.findIndex((el) => el.transactionId === id);
        transactions.value[transactionIndex] = body;
    };

    const markTransactionAsReceived = (id) => {
        const transaction = transactions.value.find((el) => el.transactionId === id);
        transaction.status = 'RECEIVED';
        transaction.transactionRequestDate = new Date();
        transaction.receivedBy = getCurrentUsername();
    };

    const translate = async (text) => {
        const response = await httpPost('translate/', {}, { text });
        return response;
    };

    watch([filters, ordering], () => {
        currentPage.value = 1;
        fetchTransactions();
    });

    const createDebounce = () => {
        let timeout = null;

        return (fnc, delayMs) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                fnc();
            }, delayMs || 400);
        };
    };

    return {
        getAuthorizedAmount,
        transactions,
        currentPage,
        maxPage,
        fetchTransactions,
        fetchTransactionsCsv,
        postReceive,
        markTransactionAsReceived,
        patchTransaction,
        updateTransactionValues,
        addTransaction,
        addRefund,
        createDebounce,
        translate,
        isLoading,
    };
};
