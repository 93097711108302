<template>
  <div class="my-3">
    <button
      class="button is-small is-primary ml-4"
      title="Bank Account Availability"
      @click="openModal"
    >
      Bank Account Availability
    </button>

    <div
      class="modal"
      :class="{'is-active': isModalOpen}"
    >
      <div
        class="modal-background"
        @click="closeModal"
      />

      <div class="modal-content">
        <div class="box">
          <div class="is-flex is-flex-wrap-wrap columns">
            <div class="field is-full column">
              <label class="checkbox">
                <input
                  v-model="active"
                  type="checkbox"
                >
                Active
              </label>
            </div>

            <div class="field is-full column">
              <div class="checkboxes">
                <label class="checkbox">
                  <input
                    v-model="days[0]"
                    type="checkbox"
                  >
                  Monday
                </label>

                <label class="checkbox ml-2">
                  <input
                    v-model="days[1]"
                    type="checkbox"
                  >
                  Tuesday
                </label>

                <label class="checkbox ml-2">
                  <input
                    v-model="days[2]"
                    type="checkbox"
                  >
                  Wednesday
                </label>

                <label class="checkbox ml-2">
                  <input
                    v-model="days[3]"
                    type="checkbox"
                  >
                  Thursday
                </label>

                <label class="checkbox ml-2">
                  <input
                    v-model="days[4]"
                    type="checkbox"
                  >
                  Friday
                </label>

                <label class="checkbox ml-2">
                  <input
                    v-model="days[5]"
                    type="checkbox"
                  >
                  Saturday
                </label>

                <label class="checkbox ml-2">
                  <input
                    v-model="days[6]"
                    type="checkbox"
                  >
                  Sunday
                </label>
              </div>
            </div>

            <div class="field is-full column">
              <label class="checkbox">
                <input
                  v-model="enabledTime"
                  type="checkbox"
                >
                Account available time
              </label>
            </div>

            <div class="field is-half column">
              <label class="checkbox">
                Set time to account is available (Japan Time)
              </label>
              <Datepicker
                v-model="startTime"
                time-picker
                time-picker-inline
                :disabled="!enabledTime"
              />
            </div>

            <div class="field is-half column">
              <label class="checkbox">
                Set time to account is available (Japan Time)
              </label>
              <Datepicker
                v-model="endTime"
                time-picker
                time-picker-inline
                :disabled="!enabledTime"
              />
            </div>

            <div class="field is-full column">
              <label class="checkbox">
                <input
                  v-model="enabledLimit"
                  type="checkbox"
                >
                Limit the number of transactions to a certain limit per day
              </label>
            </div>

            <div class="field is-half column">
              <label class="checkbox">
                Current day of operation
              </label>
              <input
                v-model="currentDay"
                :disabled="!enabledLimit"
                class="input"
                type="text"
              >
            </div>

            <div
              v-for="(field, index) in avRange"
              :key="index"
              class="columns ml-0 mr-0"
            >
              <div class="field is-one-quarter column">
                <label class="checkbox">
                  Start day {{ index + 1 }}
                </label>
                <input
                  v-model="field.startDay"
                  :disabled="!enabledLimit"
                  class="input"
                  type="text"
                >
              </div>

              <div class="field is-one-quarter column">
                <label class="checkbox">
                  End day {{ index + 1 }}
                </label>
                <input
                  v-model="field.endDay"
                  :disabled="!enabledLimit"
                  class="input"
                  type="text"
                >
              </div>

              <div class="field is-one-quarter column">
                <label class="checkbox">
                  Min Number Trx {{ index + 1 }}
                </label>
                <input
                  v-model="field.minTransactions"
                  :disabled="!enabledLimit"
                  class="input"
                  type="text"
                >
              </div>

              <div class="field is-one-quarter column">
                <label class="checkbox">
                  Max Number Trx {{ index + 1 }}
                </label>
                <input
                  v-model="field.maxTransactions"
                  :disabled="!enabledLimit"
                  class="input"
                  type="text"
                >
              </div>
            </div>

            <div class="field is-half column">
              <button
                class="button"
                :disabled="!enabledLimit"
                @click="addField"
              >
                Add
              </button>
            </div>

            <div class="field is-half column">
              <button
                class="button is-pulled-right"
                :disabled="!enabledLimit"
                @click="deleteField"
              >
                Delete
              </button>
            </div>

            <div class="field is-grouped is-grouped-right column">
              <p class="control">
                <button
                  class="button is-primary"
                  :class="{ 'is-loading': isLoading }"
                  @click="changeAvailability"
                >
                  Save
                </button>
              </p>
              <p class="control">
                <a
                  class="button is-light"
                  @click="closeModal"
                >
                  Cancel
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue';
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';

export default {
    components: {
        Datepicker,
    },

    props: {
        bankAccount: {
            type: Object,
            default: null,
        },
    },

    async setup(props) {
        const {
            changeBankAccountAvailability,
            getBankAccountActivity,
        } = inject('bank_accounts');

        const isLoading = ref(false);
        const isModalOpen = ref(false);
        const errors = ref({});
        const active = ref(true);
        const startTime = ref();
        const endTime = ref();
        const enabledTime = ref(false);
        const enabledLimit = ref(false);
        const days = ref({
            0: false,
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
        });
        const avRange = ref([
            {
                startDay: '',
                endDay: '',
                minTransactions: '',
                maxTransactions: '',
            },
        ]);
        const currentDay = ref();

        const openModal = async () => {
            const data = await getBankAccountActivity(props.bankAccount.bankAccountId);

            if (data) {
                enabledTime.value = data.timeEnabled;

                if (data.timeEnabled && data.startTime != null && data.endTime != null) {
                    const [startHour, startMinute] = data.startTime.split(':');
                    const [endHour, endMinute] = data.endTime.split(':');

                    startTime.value = {
                        hours: startHour,
                        minutes: startMinute,
                        seconds: 0,
                    };
                    endTime.value = {
                        hours: endHour,
                        minutes: endMinute,
                        seconds: 0,
                    };
                }

                days.value = {
                    0: data.days[0],
                    1: data.days[1],
                    2: data.days[2],
                    3: data.days[3],
                    4: data.days[4],
                    5: data.days[5],
                    6: data.days[6],
                };
                enabledLimit.value = data.limitEnabled;
                active.value = data.active;

                avRange.value = data.avRange;

                if (avRange.value.length === 0) {
                    avRange.value = [
                        {
                            startDay: '',
                            endDay: '',
                            minTransactions: '',
                            maxTransactions: '',
                        },
                    ];
                }

                if (data.limitStartDate) {
                    currentDay.value = data.startDateDiff;
                }
            }

            isModalOpen.value = true;
            errors.value = {};
        };

        const closeModal = () => {
            isModalOpen.value = false;
        };

        const changeAvailability = async () => {
            const currentDate = new Date();
            let limitStartDate = null;

            if (enabledLimit.value) {
                currentDate.setDate(currentDate.getDate() - parseInt(currentDay.value || 0, 10));
                // eslint-disable-next-line
                limitStartDate = `${currentDate.getFullYear()}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${('0' + currentDate.getDate()).slice(-2)}`;
            }

            await changeBankAccountAvailability(
                props.bankAccount.bankAccountId, {
                    start_time: startTime.value
                        ? `${startTime.value.hours}:${startTime.value.minutes}` : null,
                    end_time: endTime.value
                        ? `${endTime.value.hours}:${endTime.value.minutes}` : null,
                    working_time_enabled: enabledTime.value,
                    limit_enabled: enabledLimit.value,
                    days: days.value,
                    av_range: avRange.value.map((av) => ({
                        start_day: av.startDay,
                        end_day: av.endDay,
                        min_trx: av.minTransactions,
                        max_trx: av.maxTransactions,
                    })),
                    active: active.value,
                    limit_start_date: limitStartDate,
                },
            );
            closeModal();
        };

        const addField = () => {
            avRange.value.push(
                {
                    startDay: '',
                    endDay: '',
                    minTransactions: '',
                    maxTransactions: '',
                },
            );
        };

        const deleteField = () => {
            if (avRange.value.length > 1) {
                avRange.value.pop();
            }
        };

        return {
            openModal,
            closeModal,
            changeAvailability,
            isLoading,
            isModalOpen,
            startTime,
            endTime,
            enabledTime,
            enabledLimit,
            days,
            avRange,
            addField,
            deleteField,
            active,
            currentDay,
        };
    },
};
</script>
